import React, { useState } from 'react';
import translations from './translations';

export default function ControlledComponent({ language }) {
  // Initialize state for each input field
  const [inputValue1, setInputValue1] = useState('');
  const [inputValue2, setInputValue2] = useState('');
  const [inputValue3, setInputValue3] = useState('');

  // Handle change for each input field
  const handleChange1 = (event) => {
    setInputValue1(event.target.value);
  };

  const handleChange2 = (event) => {
    setInputValue2(event.target.value);
  };

  const handleChange3 = (event) => {
    setInputValue3(event.target.value);
  };

  // Calculate the product of the input values
  const multiplyValues = () => {
    const value1 = parseFloat(inputValue1) || 0;
    const value2 = parseFloat(inputValue2) || 0;
    const value3 = parseFloat(inputValue3) || 0;
    return value1 * value2 * value3;
  };

  // Get the current language's translations
  const currentTranslations = translations[language];

  return (
    <form>
      <div>
        <label>
          {currentTranslations.inputLabel1}
          <input type="number" value={inputValue1} onChange={handleChange1} />
        </label>
      </div>
      <div>
        <label>
          {currentTranslations.inputLabel2}
          <input type="number" value={inputValue2} onChange={handleChange2} />
        </label>
      </div>
      <div>
        <label>
          {currentTranslations.inputLabel3}
          <input type="number" value={inputValue3} onChange={handleChange3} />
        </label>
      </div>
      <p>
        {currentTranslations.multiplicationResult} {multiplyValues()}
      </p>
    </form>
  );
}
