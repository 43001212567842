// src/translations.js

const translations = {
  en: {
    title: "Menicon Calculator App - React PWA",
    version: "V8",
    iosInstructions: "To install this app on your iOS device, tap the share icon and then select \"Add to Home Screen\".",
    installButton: "Install App",
    closeButton: "Close",
    inputLabel1: "Input Value 1:",
    inputLabel2: "Input Value 2:",
    inputLabel3: "Input Value 3:",
    multiplicationResult: "Result of Multiplication (Value 1 x 2 x 3):"
  },
  es: {
    title: "Aplicación Calculadora Menicon - React PWA",
    version: "V8",
    iosInstructions: "Para instalar esta aplicación en tu dispositivo iOS, toca el icono de compartir y luego selecciona \"Añadir a la pantalla de inicio\".",
    installButton: "Instalar Aplicación",
    closeButton: "Cerrar",
    inputLabel1: "Valor de entrada 1:",
    inputLabel2: "Valor de entrada 2:",
    inputLabel3: "Valor de entrada 3:",
    multiplicationResult: "Resultado de la multiplicación (Valor 1 x 2 x 3):"
  },
  fr: {
    title: "Application Calculatrice Menicon - React PWA",
    version: "V8",
    iosInstructions: "Pour installer cette application sur votre appareil iOS, appuyez sur l'icône de partage puis sélectionnez \"Ajouter à l'écran d'accueil\".",
    installButton: "Installer l'application",
    closeButton: "Fermer",
    inputLabel1: "Valeur d'entrée 1:",
    inputLabel2: "Valeur d'entrée 2:",
    inputLabel3: "Valeur d'entrée 3:",
    multiplicationResult: "Résultat de la multiplication (Valeur 1 x 2 x 3):"
  },
  // Add more languages as needed
};

export default translations;
