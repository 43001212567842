// src/App.js

import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import ControlledComponent from './ControlledComponent';
import {
  initializeInstallPrompt,
  handleInstallClick,
  getIsInstallable
} from './installPrompt';
import translations from './translations';

function App() {
  const [isInstallable, setIsInstallable] = useState(false);
  const [language, setLanguage] = useState('en'); // Default language is English

  useEffect(() => {
    const cleanup = initializeInstallPrompt(setIsInstallable);
    return cleanup;
  }, []);

  // Function to handle language change
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  // Get the current language's translations
  const currentTranslations = translations[language];

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>{currentTranslations.title}</h1>
        <p>{currentTranslations.version}</p>
      </header>
      <div className="App-body">
        <ControlledComponent language={language} />
        {isInstallable && (
          <button onClick={handleInstallClick} className="install-button">
            {currentTranslations.installButton}
          </button>
        )}
      </div>

      <div className="modal" id="iosInstructions">
        <button data-toggle="modal" data-target="iosInstructions">
          {currentTranslations.closeButton}
        </button>
        <p>{currentTranslations.iosInstructions}</p>
      </div>

      {/* Language switcher */}
      <div className="language-switcher">
        <label htmlFor="language-select">Select Language:</label>
        <select id="language-select" onChange={handleLanguageChange} value={language}>
          <option value="en">English</option>
          <option value="es">Español</option>
          <option value="fr">Français</option>
          {/* Add more languages here */}
        </select>
      </div>
    </div>
  );
}

export default App;
